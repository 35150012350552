/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  createEntityAdapter,
  createSelector,
  EntityState
} from "@reduxjs/toolkit";
import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
  TypedUseQueryStateResult
} from "@reduxjs/toolkit/query/react";
import { PagedResults } from "api/RestApi";
import { WithId } from "core";
import { RootState } from "modules/store";
import { LinkedEstimate } from "./state";
import { PreConId, trueUpCompletedProjectIdString } from "./common";
import { createConnection } from "modules/signalr/init";

export interface FieldMetadata {
  isLocked: boolean;
  filterValues: string[];
}

export interface Project {
  id: string;
  lastModified?: Date;
  dateCreated?: Date;
  lastModifiedByUserId?: string | undefined;
  lastModifiedByClientId?: string;
  lastModifiedBySystemUser?: boolean;
  deleted?: boolean;
  archived?: boolean;
  companyId?: string | undefined;
  cosmosEntityName?: string | undefined;
  locationId?: string | undefined;
  fields: { [key: string]: any };
  fieldsMetadata?: { [key: string]: FieldMetadata };
  warnings?: {
    message: string;
    fieldName: string;
    warningValue: string;
  }[];
}

export interface ArchivedProject {
  id: string;
  lastModified?: Date;
  name: string;
}

export interface ProjectIdRangeResultsDto {
  success: string[];
  failed: string[];
}

export type ProjectUpdate = Pick<Project, "id" | "fields" | "fieldsMetadata">;
export type ProjectDelete = Pick<Project, "id">;
export type ProjectArchive = Pick<Project, "id" | "fields">;

const projectsAdapter = createEntityAdapter<Project>();

const authorizedBaseQuery = fetchBaseQuery({
  baseUrl: "/api/v1/businessUnits/",
  prepareHeaders: (headers, { getState }) => {
    const state = getState();
    const token = state.account.user?.idsrvAccessToken;
    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }
    return headers;
  }
});

const projectsBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = (args, api, extraOptions) => {
  const state = api.getState();
  const buId = state.account.user?.selectedBusinessUnitId;

  if (!buId) {
    return {
      error: {
        status: 400,
        statusText: "Bad Request",
        data: "No business unit selected"
      }
    };
  }

  const urlEnd = typeof args === "string" ? args : args.url;

  const isReportEndpoint =
    typeof args !== "string" && args.url.includes("reports");

  const adjustedUrl = isReportEndpoint
    ? `${buId}/${urlEnd}`
    : `${buId}/projects/${urlEnd}`;

  const adjustedArgs =
    typeof args === "string" ? adjustedUrl : { ...args, url: adjustedUrl };

  return authorizedBaseQuery(adjustedArgs, api, extraOptions);
};

export const projectsApi = createApi({
  reducerPath: "api",
  baseQuery: projectsBaseQuery,
  tagTypes: ["Project", "PreConId", "ArchivedProjects"],
  endpoints: builder => ({
    getProjects: builder.query<EntityState<Project, string>, void>({
      query: () => "?top=-1",
      transformResponse(response: PagedResults<WithId<Project>>) {
        return projectsAdapter.addMany(
          projectsAdapter.getInitialState(),
          response.results ?? []
        );
      },
      async onCacheEntryAdded(
        _arg,
        { getState, updateCachedData, cacheDataLoaded, cacheEntryRemoved }
      ) {
        const state = getState();
        const token = state.account.user?.idsrvAccessToken;
        const buId = state.account.user?.selectedBusinessUnitId;

        if (!token || !buId) return;

        const hubConnection = createConnection(token, buId);

        await cacheDataLoaded;

        const messageHandler = (projects: Project[]) => {
          if (!projects) return;

          updateCachedData(draft => {
            const projectsToUpdate = projects.filter(
              p => !(p.deleted || p.archived)
            );
            const projectsToRemove = projects
              .filter(p => p.deleted || p.archived)
              .map(p => p.id);

            projectsAdapter.upsertMany(draft, projectsToUpdate);
            projectsAdapter.removeMany(draft, projectsToRemove);
          });
        };

        hubConnection.on("UpdateProjects", messageHandler);

        const startConnection = async (retryCount = 0) => {
          try {
            return await hubConnection.start();
          } catch (error) {
            console.error("SignalR failed to connect", error);
            const delay = 1000 * (retryCount * 2);
            console.error(`Retrying in ${delay} milliseconds`);
            await new Promise(resolve => setTimeout(resolve, delay));
            return startConnection(retryCount + 1);
          }
        };

        hubConnection.onclose(() => {
          console.error("SIGNALR CONNECTION CLOSED");
          const reconnect = async () => {
            await startConnection();
          };
          void reconnect();
        });

        startConnection();
        await cacheEntryRemoved;
        await hubConnection.stop();
      },
      providesTags: (
        result = projectsAdapter.getInitialState(),
        _error,
        _arg
      ) => [
        "Project",
        ...result.ids.map(id => ({ type: "Project", id } as const))
      ]
    }),
    getArchivedProjects: builder.query<ArchivedProject[], void>({
      query: () => ({ url: "archived" }),
      providesTags: ["ArchivedProjects"]
    }),
    getArchivedProjectById: builder.query<Project, string>({
      query: projectId => ({
        url: `${projectId}`,
        method: "GET",
        params: { includeArchived: true }
      })
    }),
    editProject: builder.mutation<Project, ProjectUpdate>({
      query: project => ({
        url: `${project.id}`,
        method: "PUT",
        body: project
      }),
      async onQueryStarted(_, lifecycleApi) {
        // Pessimistic update since the last modified is provided by the server
        try {
          const { data: project } = await lifecycleApi.queryFulfilled;
          lifecycleApi.dispatch(
            projectsApi.util.updateQueryData(
              "getProjects",
              undefined,
              draft => {
                draft.entities[project.id] = project;
              }
            )
          );
        } catch {
          // Do nothing since pessimistic update but still want to catch the exception
        }
      }
    }),
    saveNewProject: builder.mutation<Project, ProjectUpdate>({
      query: project => ({
        url: "",
        method: "POST",
        body: project
      }),
      async onQueryStarted(_, lifecycleApi) {
        //Pessimistic update since the project id is provided by the server
        try {
          const { data: project } = await lifecycleApi.queryFulfilled;
          if (project.fields["preconId"]) {
            project.fields["preconId"] = trueUpCompletedProjectIdString(
              project.fields["preconId"]
            );
          }
          lifecycleApi.dispatch(
            projectsApi.util.updateQueryData(
              "getProjects",
              undefined,
              draft => {
                draft.entities[project.id] = project;
              }
            )
          );
        } catch {
          // Do nothing since pessimistic update but still want to catch the exception
        }
      }
    }),
    archiveProject: builder.mutation<Project, ProjectArchive>({
      query: project => ({
        url: `archive/${project.id}`,
        method: "PATCH"
      }),
      async onQueryStarted(project, { dispatch, queryFulfilled }) {
        const getProjectUpdateResult = dispatch(
          projectsApi.util.updateQueryData("getProjects", undefined, draft => {
            delete draft.entities[project.id];
            draft.ids = draft.ids.filter(id => id !== project.id);
          })
        );
        const getArchivedProjectsUpdateResult = dispatch(
          projectsApi.util.updateQueryData(
            "getArchivedProjects",
            undefined,
            draft => {
              const archivedProject = {
                id: project.id,
                name: project.fields["name"],
                lastModified: project.fields["lastModified"]
              };
              draft.unshift(archivedProject);
            }
          )
        );

        try {
          await queryFulfilled;
        } catch {
          getProjectUpdateResult.undo();
          getArchivedProjectsUpdateResult.undo();
        }
      }
    }),
    archiveMultipleProjects: builder.mutation<
      ProjectIdRangeResultsDto,
      string[]
    >({
      query: projects => ({
        url: "archive-range",
        method: "PATCH",
        body: projects
      }),
      invalidatesTags: ["ArchivedProjects"],
      async onQueryStarted(projects, lifecycleApi) {
        const getProjectUpdateResult = lifecycleApi.dispatch(
          projectsApi.util.updateQueryData("getProjects", undefined, draft => {
            projects.forEach(p => {
              delete draft.entities[p];
            });
          })
        );

        try {
          await lifecycleApi.queryFulfilled;
        } catch {
          getProjectUpdateResult.undo();
        }
      }
    }),
    unarchiveProject: builder.mutation<Project, ProjectArchive>({
      query: ({ id }) => ({
        url: `unarchive/${id}`,
        method: "PATCH"
      }),
      async onQueryStarted(project, { dispatch, queryFulfilled }) {
        const getUnarchivedProjectsUpdateResult = dispatch(
          projectsApi.util.updateQueryData(
            "getArchivedProjects",
            undefined,
            draft => {
              const index = draft.findIndex(p => p.id === project.id);
              if (index > -1) {
                draft.splice(index, 1);
              }
            }
          )
        );
        const getProjectUpdateResult = dispatch(
          projectsApi.util.updateQueryData("getProjects", undefined, draft => {
            draft.entities[project.id] = project;
            draft.ids.push(project.id);
          })
        );

        try {
          await queryFulfilled;
        } catch {
          getProjectUpdateResult.undo();
          getUnarchivedProjectsUpdateResult.undo();
        }
      }
    }),
    linkEstimatesToProject: builder.mutation<
      Project,
      {
        id: string;
        linkedEstimates: LinkedEstimate[];
        originalProject?: Project;
        originalProjectEstimateIds?: string[];
        meta?: { silent: boolean };
      }
    >({
      query: ({ id, linkedEstimates }) => ({
        url: `${id}/linkEstimatesToProject`,
        method: "POST",
        body: linkedEstimates
      }),
      async onQueryStarted(
        { id, linkedEstimates },
        { dispatch, queryFulfilled }
      ) {
        const getProjectUpdateResult = dispatch(
          projectsApi.util.updateQueryData("getProjects", undefined, draft => {
            const draftProject = draft.entities[id];
            const currentLinkedEstimates = draftProject.fields?.["estimates"];
            if (!currentLinkedEstimates) {
              draftProject.fields["estimates"] = [];
            }
            draftProject.fields.estimates = linkedEstimates;
          })
        );

        try {
          await queryFulfilled;
        } catch {
          getProjectUpdateResult.undo();
        }
      }
    }),
    unlinkEstimateFromProject: builder.mutation<
      Project,
      { projectId: string; estimateId: string; estimateCode?: string }
    >({
      query: ({ projectId, estimateId }) => ({
        url: `${projectId}/unlinkEstimateFromProject/${estimateId}`,
        method: "POST"
      }),
      async onQueryStarted(
        { projectId, estimateId },
        { dispatch, queryFulfilled }
      ) {
        const getProjectUpdateResult = dispatch(
          projectsApi.util.updateQueryData("getProjects", undefined, draft => {
            const draftProject = draft.entities[projectId];
            if (draftProject.fields.estimates) {
              const linkedEstimates = draftProject.fields.estimates.filter(
                (estimate: { id: string }) => estimate.id !== estimateId
              );
              draftProject.fields.estimates = linkedEstimates;
            }
          })
        );

        try {
          await queryFulfilled;
        } catch {
          getProjectUpdateResult.undo();
        }
      }
    }),
    unlinkEstimatesFromProject: builder.mutation<
      Project,
      {
        projectId: string;
        estimateIds: string[];
        meta?: { errorNotification: string };
      }
    >({
      query: ({ projectId, estimateIds }) => ({
        url: `${projectId}/unlinkEstimatesFromProject`,
        method: "POST",
        body: estimateIds
      }),
      async onQueryStarted(
        { projectId, estimateIds },
        { dispatch, queryFulfilled }
      ) {
        const getProjectUpdateResult = dispatch(
          projectsApi.util.updateQueryData("getProjects", undefined, draft => {
            const draftProject = draft.entities[projectId];
            if (draftProject.fields.estimates) {
              const linkedEstimates = draftProject.fields.estimates.filter(
                (estimate: { id: string }) => !estimateIds.includes(estimate.id)
              );
              draftProject.fields.estimates = linkedEstimates;
            }
          })
        );

        try {
          await queryFulfilled;
        } catch {
          getProjectUpdateResult.undo();
        }
      }
    }),
    deleteProject: builder.mutation<void, ProjectDelete>({
      query: project => ({
        url: `/${project.id}`,
        method: "DELETE"
      }),
      async onQueryStarted(project, lifecycleApi) {
        //Optimistically update the project in the cache
        const getProjectUpdateResult = lifecycleApi.dispatch(
          projectsApi.util.updateQueryData("getProjects", undefined, draft => {
            delete draft.entities[project.id];
            draft.ids = draft.ids.filter(id => id !== project.id);
          })
        );

        try {
          await lifecycleApi.queryFulfilled;
        } catch {
          //Undo the update if request fails
          getProjectUpdateResult.undo();
        }
      }
    }),
    deleteMultipleProjects: builder.mutation<void, string[]>({
      query: projects => ({
        url: `/range`,
        method: "DELETE",
        body: projects
      }),
      async onQueryStarted(projects, lifecycleApi) {
        //Optimistically update the project in the cache
        const getProjectUpdateResult = lifecycleApi.dispatch(
          projectsApi.util.updateQueryData("getProjects", undefined, draft => {
            projects.forEach(projectId => {
              delete draft.entities[projectId];
            });
            draft.ids = draft.ids.filter(id => !projects.includes(id));
          })
        );

        try {
          await lifecycleApi.queryFulfilled;
        } catch {
          //Undo the update if request fails
          getProjectUpdateResult.undo();
        }
      }
    }),
    getLastPreConId: builder.query<PreConId, void>({
      query: () => ({
        url: "lastPreConId",
        method: "GET"
      }),
      providesTags: ["PreConId"]
    })
  })
});

type GetFilteredProjectsFromResultArg = TypedUseQueryStateResult<
  EntityState<Project, string>,
  any,
  any
>;

export const selectProjectById = createSelector(
  (res: GetFilteredProjectsFromResultArg) => res.data,
  (_res: GetFilteredProjectsFromResultArg, id: string) => id,
  (data, id) => {
    if (!data?.entities) return null;

    const project = data.entities[id];
    return project || null;
  }
);

export const getLinkedEstimates = createSelector(
  (res: GetFilteredProjectsFromResultArg) => res.data,
  data => {
    const projectsHash = data?.entities;
    const result = new Map<string, LinkedEstimate[]>();

    if (!projectsHash) {
      return result;
    }

    const projects = Object.values(projectsHash).filter(
      p => p.fields?.estimates?.length
    );

    for (const project of projects) {
      const projectEstimates = project.fields.estimates;

      for (const estimate of projectEstimates as LinkedEstimate[]) {
        const linkedEstimates = result.get(estimate.id);

        if (linkedEstimates) {
          linkedEstimates.push(estimate);
        } else {
          result.set(estimate.id, [estimate]);
        }
      }
    }

    return result;
  }
);

export const {
  useGetProjectsQuery,
  useGetArchivedProjectsQuery,
  useEditProjectMutation,
  useSaveNewProjectMutation,
  useArchiveProjectMutation,
  useArchiveMultipleProjectsMutation,
  useUnarchiveProjectMutation,
  useDeleteProjectMutation,
  useDeleteMultipleProjectsMutation,
  useLinkEstimatesToProjectMutation,
  useUnlinkEstimateFromProjectMutation,
  useUnlinkEstimatesFromProjectMutation,
  usePrefetch,
  useGetLastPreConIdQuery,
  useGetArchivedProjectByIdQuery
} = projectsApi;
